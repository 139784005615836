import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSubscription } from '../../lib/stripe/hooks/useSubscription';
import { useCheckout } from '../../lib/stripe/hooks/useCheckout';
import { SUBSCRIPTION_PLANS } from '../../lib/stripe/plans';
import { PlanCard } from '../../components/subscription/PlanCard';
import { useAuth } from '../../lib/auth/useAuth';
import toast from 'react-hot-toast';

export function SubscriptionPage() {
  const { currentUser } = useAuth();
  const { subscription, loading: subLoading } = useSubscription();
  const { startCheckout, loading: checkoutLoading } = useCheckout();
  const navigate = useNavigate();

  const handleSelectPlan = async (planId: string) => {
    if (!currentUser) {
      navigate('/login');
      return;
    }

    try {
      if (planId === SUBSCRIPTION_PLANS[0].id) {
        // Free tier selected
        navigate('/dashboard');
      } else {
        // Premium tier selected - start Stripe checkout
        await startCheckout(planId);
      }
    } catch (error) {
      console.error('Subscription error:', error);
      toast.error('Failed to setup subscription');
    }
  };

  if (subLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-2 border-b-transparent border-blue-600" />
      </div>
    );
  }

  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-gray-900">Choose Your Plan</h1>
          <p className="mt-4 text-lg text-gray-500">
            Select the plan that best fits your needs
          </p>
        </div>

        <div className="mt-12 grid gap-8 lg:grid-cols-2 lg:gap-12">
          {SUBSCRIPTION_PLANS.map((plan) => (
            <PlanCard
              key={plan.id}
              plan={plan}
              isCurrentPlan={subscription?.tier === plan.tier}
              onSelect={() => handleSelectPlan(plan.id)}
              disabled={checkoutLoading}
            />
          ))}
        </div>
      </div>
    </div>
  );
}