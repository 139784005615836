import { useState } from 'react';
import { useAuth } from '../../auth/useAuth';
import { createCheckoutSession } from '../services/checkout.service';
import toast from 'react-hot-toast';

export function useCheckout() {
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();

  const startCheckout = async (priceId: string) => {
    if (!currentUser) {
      toast.error('Please sign in to continue');
      return;
    }

    try {
      setLoading(true);
      toast.loading('Preparing checkout...', { id: 'checkout' });
      await createCheckoutSession(currentUser.uid, priceId);
    } catch (error) {
      console.error('Checkout error:', error);
      toast.error('Failed to start checkout process', { id: 'checkout' });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    startCheckout,
    loading
  };
}