import React from 'react';
import { Sermon } from '../../lib/sermons/types';

interface SermonOutlineProps {
  sermon: Sermon;
  onSave?: () => void;
  onRegenerate?: () => void;
}

export function SermonOutline({ sermon, onSave, onRegenerate }: SermonOutlineProps) {
  return (
    <div className="bg-white shadow-sm rounded-lg p-6">
      <div className="space-y-6">
        <div>
          <h3 className="text-lg font-medium text-gray-900">Introduction</h3>
          <p className="mt-2 text-gray-600">{sermon.outline.introduction}</p>
        </div>

        <div>
          <h3 className="text-lg font-medium text-gray-900">Main Points</h3>
          <ul className="mt-2 space-y-2">
            {sermon.outline.mainPoints.map((point, index) => (
              <li key={index} className="text-gray-600">
                {index + 1}. {point}
              </li>
            ))}
          </ul>
        </div>

        <div>
          <h3 className="text-lg font-medium text-gray-900">Applications</h3>
          <ul className="mt-2 space-y-2">
            {sermon.outline.applications.map((app, index) => (
              <li key={index} className="text-gray-600">
                • {app}
              </li>
            ))}
          </ul>
        </div>

        <div>
          <h3 className="text-lg font-medium text-gray-900">Closing Prayer</h3>
          <p className="mt-2 text-gray-600">{sermon.outline.closingPrayer}</p>
        </div>

        {(onSave || onRegenerate) && (
          <div className="flex space-x-4 mt-6">
            {onRegenerate && (
              <button
                onClick={onRegenerate}
                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Regenerate
              </button>
            )}
            {onSave && (
              <button
                onClick={onSave}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                Save Sermon
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}