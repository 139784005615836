import React from 'react';
import { Link } from 'react-router-dom';
import { FileText, Plus } from 'lucide-react';
import { useSubscription } from '../../lib/stripe/hooks/useSubscription';

export function EmptySermonState() {
  const { subscription } = useSubscription();
  const canCreate = subscription?.status === 'active';

  return (
    <div className="text-center py-12 bg-white rounded-lg shadow-sm">
      <FileText className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-4 text-lg font-medium text-gray-900">
        {canCreate ? 'Create Your First Sermon' : 'Start Your Journey'}
      </h3>
      <p className="mt-2 text-sm text-gray-500 max-w-sm mx-auto">
        {canCreate 
          ? "Get started with our AI-powered sermon creation tool. We'll help you craft engaging, biblically-sound messages for your congregation."
          : "Upgrade to our premium plan to start creating AI-powered sermons that will inspire your congregation."}
      </p>
      <div className="mt-6">
        {canCreate ? (
          <Link
            to="/sermons/create"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
          >
            <Plus className="h-5 w-5 mr-2" />
            Create Your First Sermon
          </Link>
        ) : (
          <Link
            to="/subscription"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
          >
            <Plus className="h-5 w-5 mr-2" />
            Upgrade to Premium
          </Link>
        )}
      </div>
    </div>
  );
}