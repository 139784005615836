import React from 'react';
import { Crown } from 'lucide-react';

export function PremiumBadge() {
  return (
    <div className="inline-flex items-center px-3 py-1 rounded-full bg-gradient-to-r from-yellow-400 to-yellow-600 text-white text-sm font-medium shadow-sm">
      <Crown className="h-4 w-4 mr-1" />
      Premium Member
    </div>
  );
}