import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SermonForm } from '../../components/sermons/SermonForm';
import { SermonOutline } from '../../components/sermons/SermonOutline';
import { useSermonGeneration } from '../../lib/sermons/hooks/useSermonGeneration';
import { useSubscription } from '../../lib/stripe/hooks/useSubscription';
import { Sermon } from '../../lib/sermons/types';
import toast from 'react-hot-toast';

export function CreateSermonPage() {
  const [generatedSermon, setGeneratedSermon] = useState<Sermon | null>(null);
  const { generateSermon, loading } = useSermonGeneration();
  const { subscription, canCreateSermon } = useSubscription();
  const navigate = useNavigate();

  const handleSubmit = async (formData: Parameters<typeof generateSermon>[0]) => {
    if (!canCreateSermon) {
      toast.error('Please upgrade your subscription to create sermons');
      navigate('/subscription');
      return;
    }

    try {
      const sermonId = await generateSermon(formData);
      if (sermonId) {
        toast.success('Sermon generated successfully!');
        navigate(`/sermons/${sermonId}`);
      }
    } catch (error: any) {
      toast.error(error.message || 'Failed to generate sermon');
    }
  };

  if (!canCreateSermon) {
    return (
      <div className="max-w-3xl mx-auto px-4 py-12">
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
          <div className="flex">
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                {subscription?.tier === 'free' 
                  ? 'You have reached your monthly limit of sermons. Upgrade to Premium for unlimited sermons.'
                  : 'You need an active subscription to create sermons.'}{' '}
                <a href="/subscription" className="font-medium underline">
                  Upgrade your plan
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto px-4 py-12">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Create New Sermon</h1>
        {subscription?.tier === 'free' && (
          <span className="text-sm text-gray-600">
            {subscription.remainingSermons} sermon{subscription.remainingSermons !== 1 ? 's' : ''} remaining this month
          </span>
        )}
      </div>
      
      <div className="bg-white shadow-sm rounded-lg p-6">
        <SermonForm onSubmit={handleSubmit} loading={loading} />
      </div>

      {generatedSermon && (
        <div className="mt-8">
          <SermonOutline
            sermon={generatedSermon}
            onRegenerate={() => handleSubmit({
              audienceType: generatedSermon.audienceType,
              theme: generatedSermon.theme,
              scriptureReferences: generatedSermon.scriptureReferences
            })}
          />
        </div>
      )}
    </div>
  );
}