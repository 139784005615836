import { useState, useEffect } from 'react';
import { Template, TemplateAudienceType, TemplateTheme } from './types';
import { fetchTemplates, rateTemplate } from './api';
import { useAuth } from '../auth/useAuth';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';

export function useTemplates() {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { currentUser } = useAuth();

  const getTemplates = async (audienceType?: TemplateAudienceType, theme?: TemplateTheme) => {
    try {
      setLoading(true);
      const templatesData = await fetchTemplates(audienceType, theme);
      setTemplates(templatesData);
    } catch (err) {
      setError('Failed to fetch templates');
      console.error('Error fetching templates:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleRateTemplate = async (templateId: string, rating: number) => {
    if (!currentUser) return;

    try {
      const templateRef = await rateTemplate(templateId, currentUser.uid, rating);
      const template = templates.find(t => t.id === templateId);
      
      if (template) {
        const newRating = ((template.rating * template.ratingCount) + rating) / (template.ratingCount + 1);
        await updateDoc(doc(db, 'templates', templateId), {
          rating: newRating,
          ratingCount: template.ratingCount + 1,
        });
        
        // Update local state
        setTemplates(prev => prev.map(t => 
          t.id === templateId 
            ? { ...t, rating: newRating, ratingCount: t.ratingCount + 1 }
            : t
        ));
      }
    } catch (err) {
      console.error('Error rating template:', err);
      throw new Error('Failed to rate template');
    }
  };

  useEffect(() => {
    getTemplates();
  }, []);

  return {
    templates,
    loading,
    error,
    fetchTemplates: getTemplates,
    rateTemplate: handleRateTemplate,
  };
}