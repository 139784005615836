import React from 'react';
import { Link } from 'react-router-dom';
import { formatRelative } from 'date-fns';
import { Users, Calendar } from 'lucide-react';
import { Sermon } from '../../lib/sermons/types';
import { EmptySermonState } from './EmptySermonState';
import { SermonListSkeleton } from './SermonListSkeleton';

interface SermonListProps {
  sermons: Sermon[];
  loading?: boolean;
}

export function SermonList({ sermons, loading = false }: SermonListProps) {
  if (loading) {
    return <SermonListSkeleton />;
  }

  if (sermons.length === 0) {
    return <EmptySermonState />;
  }

  return (
    <div className="space-y-4">
      {sermons.map((sermon) => (
        <Link
          key={sermon.id}
          to={`/sermons/${sermon.id}`}
          className="block bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow"
        >
          <div className="p-6">
            <h3 className="text-lg font-medium text-gray-900">{sermon.title}</h3>
            <div className="mt-2 flex items-center text-sm text-gray-500 space-x-4">
              <div className="flex items-center">
                <Users className="flex-shrink-0 mr-1.5 h-4 w-4" />
                {sermon.audienceType}
              </div>
              <div className="flex items-center">
                <Calendar className="flex-shrink-0 mr-1.5 h-4 w-4" />
                {formatRelative(sermon.createdAt, new Date())}
              </div>
            </div>
            <div className="mt-2">
              <p className="text-sm text-gray-600 line-clamp-2">
                {sermon.outline.introduction}
              </p>
            </div>
            {sermon.scriptureReferences.length > 0 && (
              <div className="mt-2 flex flex-wrap gap-2">
                {sermon.scriptureReferences.map((ref, index) => (
                  <span
                    key={index}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                  >
                    {ref}
                  </span>
                ))}
              </div>
            )}
          </div>
        </Link>
      ))}
    </div>
  );
}