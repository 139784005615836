export const AUTH_ERRORS = {
  'auth/user-not-found': 'No account found with this email address',
  'auth/wrong-password': 'Incorrect password',
  'auth/email-already-in-use': 'An account already exists with this email',
  'auth/weak-password': 'Password should be at least 6 characters',
  'auth/invalid-email': 'Please enter a valid email address',
  'auth/popup-blocked': 'Popup was blocked. Please allow popups or try another sign-in method',
  'auth/popup-closed-by-user': 'Sign-in window was closed. Please try again',
  'auth/cancelled-popup-request': 'Sign-in cancelled',
  'auth/requires-recent-login': 'Please sign in again to continue',
  'auth/expired-action-code': 'This link has expired. Please request a new one',
  'auth/invalid-action-code': 'This link is invalid. It may have been used already',
} as const;

export function getAuthErrorMessage(code: string): string {
  return AUTH_ERRORS[code as keyof typeof AUTH_ERRORS] || 'An error occurred. Please try again.';
}