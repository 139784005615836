import React, { useState } from 'react';
import { SermonAudienceType } from '../../lib/sermons/types';
import { AuthButton } from '../auth/AuthButton';

interface SermonFormProps {
  onSubmit: (data: {
    audienceType: SermonAudienceType;
    theme: string;
    scriptureReferences: string[];
  }) => void;
  loading?: boolean;
}

export function SermonForm({ onSubmit, loading = false }: SermonFormProps) {
  const [audienceType, setAudienceType] = useState<SermonAudienceType>('adult');
  const [theme, setTheme] = useState('');
  const [scriptureReference, setScriptureReference] = useState('');
  const [references, setReferences] = useState<string[]>([]);

  const handleAddReference = () => {
    if (scriptureReference.trim()) {
      setReferences([...references, scriptureReference.trim()]);
      setScriptureReference('');
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      audienceType,
      theme,
      scriptureReferences: references
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Target Audience
        </label>
        <select
          value={audienceType}
          onChange={(e) => setAudienceType(e.target.value as SermonAudienceType)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        >
          <option value="adult">Adult</option>
          <option value="youth">Youth</option>
          <option value="children">Children</option>
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Theme or Topic
        </label>
        <input
          type="text"
          value={theme}
          onChange={(e) => setTheme(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          placeholder="e.g., Faith, Love, Forgiveness"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Scripture References
        </label>
        <div className="mt-1 flex space-x-2">
          <input
            type="text"
            value={scriptureReference}
            onChange={(e) => setScriptureReference(e.target.value)}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            placeholder="e.g., John 3:16"
          />
          <button
            type="button"
            onClick={handleAddReference}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
          >
            Add
          </button>
        </div>
        {references.length > 0 && (
          <div className="mt-2 flex flex-wrap gap-2">
            {references.map((ref, index) => (
              <span
                key={index}
                className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
              >
                {ref}
                <button
                  type="button"
                  onClick={() => setReferences(references.filter((_, i) => i !== index))}
                  className="ml-1 text-blue-600 hover:text-blue-800"
                >
                  ×
                </button>
              </span>
            ))}
          </div>
        )}
      </div>

      <AuthButton type="submit" disabled={loading || !theme.trim()}>
        {loading ? 'Generating Sermon...' : 'Generate Sermon'}
      </AuthButton>
    </form>
  );
}