import { useState } from 'react';
import { useAuth } from '../../auth/useAuth';
import { useSubscription } from '../../stripe/hooks/useSubscription';
import { SermonGenerationRequest } from '../types';
import { SermonService } from '../services/sermon.service';
import toast from 'react-hot-toast';

export function useSermonGeneration() {
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const { subscription } = useSubscription();

  const generateSermon = async (request: SermonGenerationRequest) => {
    if (!currentUser) {
      throw new Error('Must be logged in to generate sermons');
    }

    if (!subscription?.status || !['active', 'trialing'].includes(subscription.status)) {
      throw new Error('Please upgrade your subscription to generate sermons');
    }

    try {
      setLoading(true);
      toast.loading('Generating your sermon...', { id: 'sermon-generation' });

      const response = await fetch('/.netlify/functions/generate-sermon', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request)
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to generate sermon');
      }

      if (!data.outline) {
        throw new Error('Invalid response from sermon generation service');
      }

      // Save the sermon
      const sermonId = await SermonService.create({
        userId: currentUser.uid,
        title: `Sermon about ${request.theme}`,
        audienceType: request.audienceType,
        theme: request.theme,
        scriptureReferences: request.scriptureReferences || [],
        outline: data.outline
      });

      toast.success('Sermon generated successfully!', { id: 'sermon-generation' });
      return sermonId;
    } catch (error: any) {
      console.error('Error generating sermon:', error);
      toast.error(error.message || 'Failed to generate sermon', { id: 'sermon-generation' });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    generateSermon,
    loading
  };
}