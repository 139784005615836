import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../auth/useAuth';
import { UserSubscription } from '../types';
import { SubscriptionService } from '../services/subscription.service';

export function useSubscription() {
  const { currentUser } = useAuth();
  const [subscription, setSubscription] = useState<UserSubscription | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchSubscription = useCallback(async () => {
    if (!currentUser) {
      setSubscription(null);
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const sub = await SubscriptionService.getUserSubscription(currentUser.uid);
      setSubscription(sub);
      setError(null);
    } catch (err) {
      console.error('Error fetching subscription:', err);
      setError('Failed to fetch subscription status');
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  useEffect(() => {
    fetchSubscription();
  }, [fetchSubscription]);

  return {
    subscription,
    loading,
    error,
    refresh: fetchSubscription,
    canCreateSermon: subscription?.status === 'active',
    remainingSermons: subscription?.tier === 'premium' ? Infinity : 3,
    isPremium: subscription?.tier === 'premium'
  };
}