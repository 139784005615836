import { collection, query, where, orderBy, getDocs, addDoc, doc, getDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase/config';
import { Template, TemplateAudienceType, TemplateTheme } from './types';

export async function fetchTemplates(audienceType?: TemplateAudienceType, theme?: TemplateTheme) {
  try {
    let templatesQuery = query(collection(db, 'templates'), orderBy('createdAt', 'desc'));

    if (audienceType) {
      templatesQuery = query(templatesQuery, where('audienceType', '==', audienceType));
    }
    if (theme) {
      templatesQuery = query(templatesQuery, where('theme', '==', theme));
    }

    const snapshot = await getDocs(templatesQuery);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt.toDate(),
      updatedAt: doc.data().updatedAt.toDate(),
    })) as Template[];
  } catch (error) {
    console.error('Error fetching templates:', error);
    return [];
  }
}

export async function rateTemplate(templateId: string, userId: string, rating: number) {
  try {
    const templateRef = doc(db, 'templates', templateId);
    const templateDoc = await getDoc(templateRef);
    
    if (!templateDoc.exists()) {
      throw new Error('Template not found');
    }

    const ratingsRef = collection(db, 'ratings');
    await addDoc(ratingsRef, {
      templateId,
      userId,
      rating,
      createdAt: serverTimestamp(),
    });

    return templateRef;
  } catch (error) {
    console.error('Error rating template:', error);
    throw error;
  }
}