import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../lib/auth/useAuth';
import { FileText, Book, Users, Star, Plus } from 'lucide-react';
import { useSubscription } from '../lib/stripe/hooks/useSubscription';
import { useSermonList } from '../lib/sermons/hooks/useSermonList';
import { PremiumBadge } from '../components/subscription/PremiumBadge';
import toast from 'react-hot-toast';

export function DashboardPage() {
  const { currentUser } = useAuth();
  const { subscription, loading: subLoading } = useSubscription();
  const { sermons, loading: sermonsLoading } = useSermonList();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Handle Stripe success redirect
  useEffect(() => {
    const sessionId = searchParams.get('session_id');
    const success = searchParams.get('success');

    if (sessionId && success === 'true') {
      toast.success('Subscription activated successfully!');
      // Clean up URL parameters
      navigate('/dashboard', { replace: true });
    }
  }, [searchParams, navigate]);

  const stats = [
    { 
      name: 'Sermons Created', 
      value: sermons?.length || '0', 
      icon: FileText 
    },
    { 
      name: 'Templates Used', 
      value: '0', 
      icon: Book 
    },
    { 
      name: 'Community Rating', 
      value: '5.0', 
      icon: Star 
    },
    { 
      name: 'Audience Reach', 
      value: '0', 
      icon: Users 
    },
  ];

  const handleCreateSermon = () => {
    navigate('/sermons/create');
  };

  if (subLoading || sermonsLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-2 border-b-transparent border-blue-600" />
      </div>
    );
  }

  return (
    <div className="py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
          {subscription?.status === 'active' && (
            <button
              onClick={handleCreateSermon}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
            >
              <Plus className="-ml-1 mr-2 h-5 w-5" />
              Create New Sermon
            </button>
          )}
        </div>
        
        <div className="mt-4">
          <div className="bg-white shadow-sm rounded-lg p-6">
            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-lg font-medium text-gray-900">
                  Welcome back, {currentUser?.displayName || currentUser?.email}
                </h2>
                <p className="mt-1 text-sm text-gray-500">
                  Here's what's happening with your account today.
                </p>
              </div>
              {subscription?.tier === 'premium' && <PremiumBadge />}
            </div>
          </div>
        </div>

        {/* Rest of the dashboard content remains the same */}
        {/* ... */}
      </div>
    </div>
  );
}