import React from 'react';
import { Link } from 'react-router-dom';
import { Plus } from 'lucide-react';
import { SermonList } from '../../components/sermons/SermonList';
import { useSermonList } from '../../lib/sermons/hooks/useSermonList';
import { useSubscription } from '../../lib/stripe/hooks/useSubscription';

export function SermonListPage() {
  const { sermons, loading, error } = useSermonList();
  const { subscription } = useSubscription();
  const canCreate = subscription?.status === 'active';

  return (
    <div className="py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-semibold text-gray-900">My Sermons</h1>
          {canCreate && (
            <Link
              to="/sermons/create"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
            >
              <Plus className="-ml-1 mr-2 h-5 w-5" />
              New Sermon
            </Link>
          )}
        </div>

        {error && (
          <div className="mt-6 bg-red-50 border-l-4 border-red-400 p-4">
            <div className="flex">
              <div className="ml-3">
                <p className="text-sm text-red-700">{error}</p>
              </div>
            </div>
          </div>
        )}

        <div className="mt-6">
          <SermonList sermons={sermons} loading={loading} />
        </div>
      </div>
    </div>
  );
}