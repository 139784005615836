import React, { useState } from 'react';
import { useTemplates } from '../../lib/templates/useTemplates';
import { TemplateCard } from '../../components/templates/TemplateCard';
import { TemplateFilters } from '../../components/templates/TemplateFilters';
import { TemplateAudienceType, TemplateTheme } from '../../types/template';
import { useAuth } from '../../lib/auth/useAuth';
import toast from 'react-hot-toast';

export function TemplatesPage() {
  const { templates, loading, error, fetchTemplates, rateTemplate } = useTemplates();
  const [selectedAudience, setSelectedAudience] = useState<TemplateAudienceType>();
  const [selectedTheme, setSelectedTheme] = useState<TemplateTheme>();
  const { currentUser } = useAuth();

  const handleRateTemplate = async (templateId: string, rating: number) => {
    if (!currentUser) {
      toast.error('Please sign in to rate templates');
      return;
    }

    try {
      await rateTemplate(templateId, rating);
      toast.success('Thank you for rating!');
    } catch (error) {
      toast.error('Failed to submit rating');
    }
  };

  const handleFilter = () => {
    fetchTemplates(selectedAudience, selectedTheme);
  };

  if (error) {
    return (
      <div className="text-center py-12">
        <p className="text-red-600">Failed to load templates. Please try again later.</p>
      </div>
    );
  }

  return (
    <div className="py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-semibold text-gray-900">Community Templates</h1>
          {currentUser && (
            <button
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
              onClick={() => {/* TODO: Implement template creation */}}
            >
              Share Template
            </button>
          )}
        </div>

        <div className="mt-6 grid grid-cols-1 gap-6 lg:grid-cols-4">
          <div className="lg:col-span-1">
            <TemplateFilters
              selectedAudience={selectedAudience}
              selectedTheme={selectedTheme}
              onAudienceChange={setSelectedAudience}
              onThemeChange={setSelectedTheme}
            />
            <button
              onClick={handleFilter}
              className="mt-4 w-full px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
            >
              Apply Filters
            </button>
          </div>

          <div className="lg:col-span-3">
            {loading ? (
              <div className="flex justify-center py-12">
                <div className="animate-spin rounded-full h-8 w-8 border-2 border-b-transparent border-blue-600" />
              </div>
            ) : (
              <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                {templates.map((template) => (
                  <TemplateCard
                    key={template.id}
                    template={template}
                    onRate={(rating) => handleRateTemplate(template.id, rating)}
                  />
                ))}
                {templates.length === 0 && (
                  <div className="col-span-2 text-center py-12 text-gray-500">
                    No templates found matching your criteria.
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}