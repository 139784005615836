import React, { useState } from 'react';
import { useAuth } from '../../lib/auth/useAuth';
import { AuthInput } from './AuthInput';
import { AuthButton } from './AuthButton';
import toast from 'react-hot-toast';
import { getAuthErrorMessage } from '../../lib/auth/authErrors';

export function PasswordResetForm() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const { resetPassword } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      setLoading(true);
      await resetPassword(email);
      toast.success('Password reset email sent! Please check your inbox.');
      setEmail('');
    } catch (error: any) {
      toast.error(getAuthErrorMessage(error.code));
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <AuthInput
        id="email"
        name="email"
        type="email"
        label="Email address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        autoComplete="email"
      />
      <AuthButton type="submit" disabled={loading}>
        {loading ? 'Sending...' : 'Reset Password'}
      </AuthButton>
    </form>
  );
}