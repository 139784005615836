import React from 'react';
import { clsx } from 'clsx';
import { AlertCircle } from 'lucide-react';

interface AuthInputProps {
  id: string;
  name: string;
  type: string;
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  required?: boolean;
  error?: string;
  touched?: boolean;
}

export function AuthInput({
  id,
  name,
  type,
  label,
  value,
  onChange,
  autoComplete,
  required = true,
  error,
  touched
}: AuthInputProps) {
  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
        {required && <span className="text-red-500 ml-1">*</span>}
      </label>
      <div className="mt-1 relative">
        <input
          id={id}
          name={name}
          type={type}
          autoComplete={autoComplete}
          required={required}
          value={value}
          onChange={onChange}
          className={clsx(
            "appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500",
            {
              "border-red-300 text-red-900": touched && error,
              "border-gray-300": !touched || !error
            }
          )}
          aria-invalid={touched && error ? "true" : "false"}
          aria-describedby={error ? `${id}-error` : undefined}
        />
        {touched && error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <AlertCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>
      {touched && error && (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {error}
        </p>
      )}
    </div>
  );
}