import React from 'react';
import { Check } from 'lucide-react';
import { SubscriptionPlan } from '../../lib/stripe/types';

interface PlanCardProps {
  plan: SubscriptionPlan;
  isCurrentPlan?: boolean;
  onSelect: (plan: SubscriptionPlan) => void;
}

export function PlanCard({ plan, isCurrentPlan, onSelect }: PlanCardProps) {
  return (
    <div className={`bg-white rounded-lg shadow-sm p-6 ${isCurrentPlan ? 'ring-2 ring-blue-500' : ''}`}>
      <h3 className="text-lg font-semibold text-gray-900">{plan.name}</h3>
      <p className="mt-2 text-sm text-gray-500">{plan.description}</p>
      
      <div className="mt-4">
        <span className="text-3xl font-bold text-gray-900">${plan.price}</span>
        <span className="text-gray-500">/{plan.interval}</span>
      </div>

      <ul className="mt-6 space-y-4">
        {plan.features.map((feature, index) => (
          <li key={index} className="flex items-start">
            <Check className="h-5 w-5 text-blue-500 mr-2" />
            <span className="text-sm text-gray-600">{feature}</span>
          </li>
        ))}
      </ul>

      <button
        onClick={() => onSelect(plan)}
        className={`mt-8 w-full py-2 px-4 rounded-md text-sm font-medium ${
          isCurrentPlan
            ? 'bg-gray-100 text-gray-800 cursor-default'
            : 'bg-blue-600 text-white hover:bg-blue-700'
        }`}
        disabled={isCurrentPlan}
      >
        {isCurrentPlan ? 'Current Plan' : 'Select Plan'}
      </button>
    </div>
  );
}