import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { AuthProvider } from './contexts/AuthContext';
import { useAuth } from './lib/auth/useAuth';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import { Layout } from './components/layout/Layout';
import { HomePage } from './pages/HomePage';
import { LoginPage } from './pages/auth/LoginPage';
import { SignupPage } from './pages/auth/SignupPage';
import { OnboardingPage } from './pages/auth/OnboardingPage';
import { PasswordResetPage } from './pages/auth/PasswordResetPage';
import { DashboardPage } from './pages/DashboardPage';
import { TemplatesPage } from './pages/templates/TemplatesPage';
import { SermonListPage } from './pages/sermons/SermonListPage';
import { CreateSermonPage } from './pages/sermons/CreateSermonPage';
import { SubscriptionPage } from './pages/subscription/SubscriptionPage';
import { StripeSuccessHandler } from './components/subscription/StripeSuccessHandler';

function AuthenticatedRedirect({ children }: { children: React.ReactNode }) {
  const { currentUser, loading } = useAuth();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-2 border-b-transparent border-blue-600" />
      </div>
    );
  }

  if (currentUser) {
    return <Navigate to="/dashboard" replace />;
  }

  return <>{children}</>;
}

export default function App() {
  return (
    <Router>
      <AuthProvider>
        <Layout>
          <Routes>
            <Route
              path="/"
              element={
                <AuthenticatedRedirect>
                  <HomePage />
                </AuthenticatedRedirect>
              }
            />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/reset-password" element={<PasswordResetPage />} />
            <Route
              path="/onboarding"
              element={
                <ProtectedRoute>
                  <OnboardingPage />
                </ProtectedRoute>
              }
            />
            <Route path="/templates" element={<TemplatesPage />} />
            <Route path="/subscription" element={<SubscriptionPage />} />
            <Route
              path="/subscription/success"
              element={
                <ProtectedRoute>
                  <StripeSuccessHandler />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <DashboardPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/sermons"
              element={
                <ProtectedRoute>
                  <SermonListPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/sermons/create"
              element={
                <ProtectedRoute>
                  <CreateSermonPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Layout>
        <Toaster position="top-right" />
      </AuthProvider>
    </Router>
  );
}