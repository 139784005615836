import { collection, addDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { STRIPE_CONFIG } from '../config/stripe';
import { getPlanByTier } from '../plans';

export async function createCheckoutSession(userId: string, priceId: string): Promise<void> {
  try {
    // Don't create checkout session for free tier
    if (priceId === STRIPE_CONFIG.prices.FREE) {
      return;
    }

    const plan = getPlanByTier('premium');
    if (!plan) {
      throw new Error('Invalid plan selected');
    }

    // Create a new checkout session
    const checkoutSessionRef = await addDoc(
      collection(db, 'customers', userId, 'checkout_sessions'),
      {
        price: STRIPE_CONFIG.prices.PREMIUM,
        success_url: `${window.location.origin}/subscription/success?session_id={CHECKOUT_SESSION_ID}`,
        cancel_url: `${window.location.origin}/subscription?canceled=true`,
        mode: 'subscription',
        allow_promotion_codes: true,
        metadata: {
          userId,
          tier: 'premium'
        }
      }
    );

    // Listen to the session and redirect when ready
    onSnapshot(checkoutSessionRef, async (snap) => {
      const { error, url } = snap.data() || {};
      
      if (error) {
        throw new Error(`An error occurred: ${error.message}`);
      }
      
      if (url) {
        window.location.assign(url);
      }
    });
  } catch (error) {
    console.error('Error creating checkout session:', error);
    throw error;
  }
}