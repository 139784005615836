import React from 'react';
import { TemplateAudienceType, TemplateTheme } from '../../types/template';

interface TemplateFiltersProps {
  selectedAudience: TemplateAudienceType | undefined;
  selectedTheme: TemplateTheme | undefined;
  onAudienceChange: (audience: TemplateAudienceType | undefined) => void;
  onThemeChange: (theme: TemplateTheme | undefined) => void;
}

export function TemplateFilters({
  selectedAudience,
  selectedTheme,
  onAudienceChange,
  onThemeChange,
}: TemplateFiltersProps) {
  return (
    <div className="bg-white p-4 rounded-lg shadow-sm space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Audience</label>
        <select
          value={selectedAudience || ''}
          onChange={(e) => onAudienceChange(e.target.value as TemplateAudienceType || undefined)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        >
          <option value="">All Audiences</option>
          <option value="adult">Adult</option>
          <option value="youth">Youth</option>
          <option value="children">Children</option>
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Theme</label>
        <select
          value={selectedTheme || ''}
          onChange={(e) => onThemeChange(e.target.value as TemplateTheme || undefined)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
        >
          <option value="">All Themes</option>
          <option value="faith">Faith</option>
          <option value="hope">Hope</option>
          <option value="love">Love</option>
          <option value="forgiveness">Forgiveness</option>
          <option value="grace">Grace</option>
          <option value="worship">Worship</option>
          <option value="prayer">Prayer</option>
          <option value="service">Service</option>
          <option value="discipleship">Discipleship</option>
          <option value="evangelism">Evangelism</option>
        </select>
      </div>
    </div>
  );
}