import React from 'react';
import { clsx } from 'clsx';

interface AuthButtonProps {
  type: 'submit' | 'button';
  disabled?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  variant?: 'primary' | 'outline';
  className?: string;
}

export function AuthButton({ 
  type, 
  disabled = false, 
  onClick, 
  children,
  variant = 'primary',
  className = ''
}: AuthButtonProps) {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={clsx(
        'w-full flex justify-center py-2 px-4 border rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 transition-colors',
        {
          'border-transparent text-white bg-blue-600 hover:bg-blue-700': variant === 'primary',
          'border-gray-300 text-gray-700 bg-white hover:bg-gray-50': variant === 'outline'
        },
        className
      )}
    >
      {children}
    </button>
  );
}