import { collection, query, where, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { UserSubscription } from '../types';
import { STRIPE_CONFIG } from '../config/stripe';

export const SubscriptionService = {
  async getUserSubscription(userId: string): Promise<UserSubscription | null> {
    try {
      // Check Stripe subscriptions first
      const customerRef = collection(db, 'customers', userId, 'subscriptions');
      const q = query(customerRef, where('status', 'in', ['active', 'trialing']));
      const snapshot = await getDocs(q);

      if (!snapshot.empty) {
        const stripeSubscription = snapshot.docs[0].data();
        return {
          id: snapshot.docs[0].id,
          userId,
          status: stripeSubscription.status,
          tier: stripeSubscription.metadata?.tier || 'free',
          currentPeriodStart: new Date(stripeSubscription.current_period_start * 1000),
          currentPeriodEnd: new Date(stripeSubscription.current_period_end * 1000),
          cancelAtPeriodEnd: stripeSubscription.cancel_at_period_end,
          stripeCustomerId: stripeSubscription.customer,
          stripeSubscriptionId: snapshot.docs[0].id
        };
      }

      // Create default free subscription if none exists
      return this.createFreeSubscription(userId);
    } catch (error) {
      console.error('Error getting subscription:', error);
      return null;
    }
  },

  async createFreeSubscription(userId: string): Promise<UserSubscription> {
    const subscriptionData = {
      userId,
      tier: 'free' as const,
      status: 'active' as const,
      currentPeriodStart: new Date(),
      currentPeriodEnd: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
      cancelAtPeriodEnd: false
    };

    const docRef = await addDoc(collection(db, 'subscriptions'), {
      ...subscriptionData,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });

    return {
      ...subscriptionData,
      id: docRef.id
    };
  },

  async createSubscription(userId: string, priceId: string): Promise<void> {
    if (priceId === STRIPE_CONFIG.prices.FREE) {
      await this.createFreeSubscription(userId);
      return;
    }

    // Create a checkout session for premium subscription
    const checkoutSessionRef = await addDoc(
      collection(db, 'customers', userId, 'checkout_sessions'),
      {
        price: priceId,
        success_url: STRIPE_CONFIG.successUrl,
        cancel_url: STRIPE_CONFIG.cancelUrl,
        mode: 'subscription',
        allow_promotion_codes: true,
        metadata: {
          userId,
          tier: 'premium'
        }
      }
    );

    // Wait for the session URL
    const unsubscribe = onSnapshot(checkoutSessionRef, (snap) => {
      const { error, url } = snap.data() || {};
      
      if (error) {
        throw new Error(`An error occurred: ${error.message}`);
      }
      
      if (url) {
        window.location.assign(url);
      }
    });

    // Clean up listener after 2 minutes
    setTimeout(() => unsubscribe(), 120000);
  }
};

export const { createSubscription, getUserSubscription } = SubscriptionService;