import { loadStripe } from '@stripe/stripe-js';

export const STRIPE_CONFIG = {
  publishableKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
  products: {
    FREE: 'free_tier',
    PREMIUM: 'prod_RODfT2pSYWoHUb'
  },
  prices: {
    FREE: 'free_tier_price',
    PREMIUM: 'price_1OqKjbKZqhqQYDHa8vVZWxxx' // Replace with your actual Stripe price ID
  },
  successUrl: `${window.location.origin}/subscription/success?session_id={CHECKOUT_SESSION_ID}`,
  cancelUrl: `${window.location.origin}/subscription?canceled=true`
} as const;

export const stripePromise = loadStripe(STRIPE_CONFIG.publishableKey);