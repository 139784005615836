import { useState, useEffect, useCallback } from 'react';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { Sermon } from '../types';
import { useAuth } from '../../auth/useAuth';

export function useSermonList() {
  const [sermons, setSermons] = useState<Sermon[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { currentUser } = useAuth();

  const fetchSermons = useCallback(async () => {
    if (!currentUser) {
      setSermons([]);
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const sermonsRef = collection(db, 'sermons');
      const sermonsQuery = query(
        sermonsRef,
        where('userId', '==', currentUser.uid),
        orderBy('createdAt', 'desc')
      );

      const snapshot = await getDocs(sermonsQuery);
      const sermonsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate() || new Date(),
        updatedAt: doc.data().updatedAt?.toDate() || new Date(),
      })) as Sermon[];

      setSermons(sermonsData);
    } catch (err: any) {
      console.error('Error fetching sermons:', err);
      // Don't set error for empty sermon list
      if (err.code !== 'failed-precondition') {
        setError(err.code === 'permission-denied' 
          ? 'You do not have permission to access these sermons' 
          : 'Failed to load sermons');
      }
      setSermons([]);
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      fetchSermons();
    } else {
      setSermons([]);
      setLoading(false);
      setError(null);
    }
  }, [fetchSermons, currentUser]);

  return { 
    sermons, 
    loading, 
    error,
    refresh: fetchSermons,
    hasSermons: sermons.length > 0
  };
}