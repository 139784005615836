import React from 'react';
import { Star, Users, Clock } from 'lucide-react';
import { Template } from '../../lib/templates/types';
import { formatRelative } from 'date-fns';

interface TemplateCardProps {
  template: Template;
  onRate: (rating: number) => void;
}

export function TemplateCard({ template, onRate }: TemplateCardProps) {
  const formattedDate = formatRelative(template.createdAt, new Date());

  return (
    <div className="bg-white rounded-lg shadow-sm overflow-hidden">
      <div className="p-6">
        <div className="flex justify-between items-start">
          <div>
            <h3 className="text-lg font-medium text-gray-900">{template.title}</h3>
            <p className="mt-1 text-sm text-gray-500">{template.description}</p>
          </div>
          {template.isPremium && (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
              Premium
            </span>
          )}
        </div>

        <div className="mt-4 flex items-center space-x-4 text-sm text-gray-500">
          <div className="flex items-center">
            <Users className="h-4 w-4 mr-1" />
            {template.audienceType}
          </div>
          <div className="flex items-center">
            <Clock className="h-4 w-4 mr-1" />
            {formattedDate}
          </div>
        </div>

        <div className="mt-4 flex items-center justify-between">
          <div className="flex items-center">
            <Star className="h-5 w-5 text-yellow-400" />
            <span className="ml-1 text-sm text-gray-600">
              {template.rating.toFixed(1)} ({template.ratingCount} ratings)
            </span>
          </div>
          <div className="text-sm text-gray-500">
            by {template.creatorName}
          </div>
        </div>

        <div className="mt-4 flex space-x-2">
          {[1, 2, 3, 4, 5].map((star) => (
            <button
              key={star}
              onClick={() => onRate(star)}
              className="text-gray-300 hover:text-yellow-400"
            >
              <Star
                className={`h-5 w-5 ${
                  template.rating >= star ? 'text-yellow-400' : 'text-gray-300'
                }`}
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}