import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Menu, X, Church, LogOut, Crown } from 'lucide-react';
import { useAuth } from '../../lib/auth/useAuth';
import { useSubscription } from '../../lib/stripe/hooks/useSubscription';

export function Navbar() {
  const [isOpen, setIsOpen] = React.useState(false);
  const { currentUser, logout } = useAuth();
  const { subscription } = useSubscription();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const showUpgrade = currentUser && subscription?.tier === 'free';

  return (
    <nav className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <Link to={currentUser ? '/dashboard' : '/'} className="flex items-center">
              <Church className="h-8 w-8 text-blue-600" />
              <span className="ml-2 text-xl font-semibold text-gray-900">iPreach.ai</span>
            </Link>
          </div>
          
          <div className="hidden sm:ml-6 sm:flex sm:items-center space-x-8">
            {currentUser ? (
              <>
                <Link to="/dashboard" className="text-gray-600 hover:text-blue-600">Dashboard</Link>
                <Link to="/sermons" className="text-gray-600 hover:text-blue-600">My Sermons</Link>
                <Link to="/templates" className="text-gray-600 hover:text-blue-600">Templates</Link>
                {showUpgrade && (
                  <Link
                    to="/subscription"
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-blue-600 to-indigo-600 hover:from-blue-700 hover:to-indigo-700"
                  >
                    <Crown className="h-4 w-4 mr-2" />
                    Upgrade Plan
                  </Link>
                )}
                <button
                  onClick={handleLogout}
                  className="flex items-center text-gray-600 hover:text-blue-600"
                >
                  <LogOut className="h-5 w-5 mr-1" />
                  Logout
                </button>
              </>
            ) : (
              <>
                <Link to="/login" className="text-gray-600 hover:text-blue-600">Login</Link>
                <Link to="/signup" className="text-gray-600 hover:text-blue-600">Sign Up</Link>
              </>
            )}
          </div>

          {/* Mobile menu button */}
          <div className="flex items-center sm:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-600 hover:text-blue-600"
            >
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="sm:hidden">
          <div className="pt-2 pb-3 space-y-1">
            {currentUser ? (
              <>
                <Link
                  to="/dashboard"
                  className="block px-3 py-2 text-base font-medium text-gray-600 hover:text-blue-600"
                >
                  Dashboard
                </Link>
                <Link
                  to="/sermons"
                  className="block px-3 py-2 text-base font-medium text-gray-600 hover:text-blue-600"
                >
                  My Sermons
                </Link>
                <Link
                  to="/templates"
                  className="block px-3 py-2 text-base font-medium text-gray-600 hover:text-blue-600"
                >
                  Templates
                </Link>
                {showUpgrade && (
                  <Link
                    to="/subscription"
                    className="block px-3 py-2 text-base font-medium text-white bg-gradient-to-r from-blue-600 to-indigo-600"
                  >
                    <div className="flex items-center">
                      <Crown className="h-4 w-4 mr-2" />
                      Upgrade Plan
                    </div>
                  </Link>
                )}
                <button
                  onClick={handleLogout}
                  className="block w-full text-left px-3 py-2 text-base font-medium text-gray-600 hover:text-blue-600"
                >
                  Logout
                </button>
              </>
            ) : (
              <>
                <Link
                  to="/login"
                  className="block px-3 py-2 text-base font-medium text-gray-600 hover:text-blue-600"
                >
                  Login
                </Link>
                <Link
                  to="/signup"
                  className="block px-3 py-2 text-base font-medium text-gray-600 hover:text-blue-600"
                >
                  Sign Up
                </Link>
              </>
            )}
          </div>
        </div>
      )}
    </nav>
  );
}