import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../lib/auth/useAuth';
import { SUBSCRIPTION_PLANS } from '../../lib/stripe/plans';
import { PlanCard } from '../../components/subscription/PlanCard';
import { createSubscription } from '../../lib/stripe/services/subscription.service';
import toast from 'react-hot-toast';

export function OnboardingPage() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleSelectPlan = async (planId: string) => {
    if (!currentUser) {
      navigate('/login');
      return;
    }

    try {
      toast.loading('Setting up your account...', { id: 'onboarding' });
      await createSubscription(currentUser.uid, planId);
      
      if (planId === SUBSCRIPTION_PLANS[0].id) {
        // Free tier selected
        toast.success('Welcome to iPreach.ai!', { id: 'onboarding' });
        navigate('/dashboard');
      } else {
        // Premium tier selected - redirect handled by Stripe
        toast.success('Redirecting to checkout...', { id: 'onboarding' });
      }
    } catch (error) {
      console.error('Subscription error:', error);
      toast.error('Failed to setup subscription', { id: 'onboarding' });
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-3xl font-bold text-gray-900">Welcome to iPreach.ai!</h1>
          <p className="mt-4 text-lg text-gray-500">
            Choose your plan to get started
          </p>
        </div>

        <div className="mt-12 grid gap-8 lg:grid-cols-2 lg:gap-12">
          {SUBSCRIPTION_PLANS.map((plan) => (
            <PlanCard
              key={plan.id}
              plan={plan}
              onSelect={() => handleSelectPlan(plan.id)}
            />
          ))}
        </div>

        <div className="mt-8 text-center text-sm text-gray-500">
          You can change your plan at any time from your account settings.
        </div>
      </div>
    </div>
  );
}