import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../lib/auth/useAuth';
import { useSubscription } from '../../lib/stripe/hooks/useSubscription';
import toast from 'react-hot-toast';

export function StripeSuccessHandler() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { refresh } = useSubscription();
  
  useEffect(() => {
    const sessionId = searchParams.get('session_id');
    const success = searchParams.get('success');
    
    if (sessionId && success === 'true') {
      // Refresh subscription data
      refresh().then(() => {
        toast.success('Subscription activated successfully!');
        navigate('/dashboard', { replace: true });
      });
    } else {
      // No session ID found, redirect to dashboard
      navigate('/dashboard', { replace: true });
    }
  }, [searchParams, navigate, refresh]);

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-8 w-8 border-2 border-b-transparent border-blue-600" />
    </div>
  );
}