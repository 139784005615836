import { SubscriptionPlan } from './types';
import { STRIPE_CONFIG } from './config/stripe';

export const SUBSCRIPTION_PLANS: SubscriptionPlan[] = [
  {
    id: STRIPE_CONFIG.prices.FREE,
    name: 'Free',
    description: 'Basic access to sermon creation tools',
    price: 0,
    interval: 'month',
    tier: 'free',
    features: [
      'Up to 3 sermons per month',
      'KJV Bible version only',
      'Basic sermon templates',
      'View community templates'
    ],
    limits: {
      sermonsPerMonth: 3,
      bibleVersions: ['KJV'],
      templates: false,
      communitySharing: false,
      specialContent: false
    }
  },
  {
    id: STRIPE_CONFIG.prices.PREMIUM,
    name: 'Premium',
    description: 'Full access to all features',
    price: 19.99,
    interval: 'month',
    tier: 'premium',
    features: [
      'Unlimited sermons',
      'All Bible versions',
      'Premium templates',
      'Community sharing',
      'Youth & children content',
      'Priority support'
    ],
    limits: {
      sermonsPerMonth: Infinity,
      bibleVersions: ['KJV', 'NIV', 'ESV', 'NKJV', 'NLT'],
      templates: true,
      communitySharing: true,
      specialContent: true
    }
  }
];

// Helper functions
export function getPlanById(planId: string): SubscriptionPlan | undefined {
  return SUBSCRIPTION_PLANS.find(plan => plan.id === planId);
}

export function getPlanByTier(tier: 'free' | 'premium'): SubscriptionPlan | undefined {
  return SUBSCRIPTION_PLANS.find(plan => plan.tier === tier);
}