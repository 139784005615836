import { collection, addDoc, serverTimestamp, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { Sermon } from '../types';

export const SermonService = {
  async create(sermon: Omit<Sermon, 'id' | 'createdAt' | 'updatedAt'>) {
    try {
      const docRef = await addDoc(collection(db, 'sermons'), {
        ...sermon,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      return docRef.id;
    } catch (error: any) {
      console.error('Error creating sermon:', error);
      throw new Error(error.code === 'permission-denied' 
        ? 'You do not have permission to create sermons' 
        : 'Failed to create sermon');
    }
  },

  async update(id: string, updates: Partial<Omit<Sermon, 'id' | 'createdAt' | 'updatedAt'>>) {
    try {
      const docRef = doc(db, 'sermons', id);
      await updateDoc(docRef, {
        ...updates,
        updatedAt: serverTimestamp(),
      });
    } catch (error: any) {
      console.error('Error updating sermon:', error);
      throw new Error(error.code === 'permission-denied'
        ? 'You do not have permission to update this sermon'
        : 'Failed to update sermon');
    }
  },

  async delete(id: string) {
    try {
      const docRef = doc(db, 'sermons', id);
      await deleteDoc(docRef);
    } catch (error: any) {
      console.error('Error deleting sermon:', error);
      throw new Error(error.code === 'permission-denied'
        ? 'You do not have permission to delete this sermon'
        : 'Failed to delete sermon');
    }
  }
};